<template>
	<div class="listBox">
		<h2 class="title">제품</h2>
		<div class="searchBox">
			<div class="seachOptions">
				<div class="searchItem">
					<span class="label">필터:</span>
					<a class="option">
						<span>사용 가능성</span>
						<el-icon>
							<ArrowDown />
						</el-icon>
					</a>
					<!-- <a class="option">
						<span>가격</span>
						<el-icon>
							<ArrowDown />
						</el-icon>
					</a> -->
				</div>
				<div class="searchItem">
					<!-- <span class="label">정렬 기준:</span>
					<el-select v-model="value" placeholder="Select" size="small">
					    <el-option
					      v-for="item in options"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value"
					    />
					</el-select> -->
					<span class="label">{{total}}개 제품</span>
				</div>
			</div>
			<!-- 搜索条件 -->
			<!-- <div class="seachParams">
				<ul>
					<li>
						<el-tag type="info" effect="plain" round closable @close="handleClose">Tag 1</el-tag>
					</li>
					<li>
						<el-tag type="info" effect="plain" round closable @close="handleClose">Tag 2</el-tag>
					</li>
					<li><a>모두 제거</a></li>
				</ul>
			</div> -->
		</div>
		<div class="listItem">
		  <ul>
			<template v-for="(item,index) in goodList" :key="index">
				<li class="item">
					<div class="imgBox">
						<img :src="item.img" />
						<!-- <span class="tag">강시</span> -->
					</div>
					<div class="desBox">
						<h3 class="title"><router-link :to="'/products/detail/'+item.id">{{item.title}}</router-link></h3>
						<p class="price">
							<span class="original">${{item.originalPrice}} USD</span>
							<span class="onsale">${{item.presentPrice}} USD</span>
						</p>
					</div>
				</li>
			</template>
		  </ul>
		</div>
	</div>
</template>

<script setup>
	import { ArrowDown } from '@element-plus/icons-vue'
	import { ref, onMounted } from "vue";
	import { getGoodList } from '@/api/index'
	
	const value = ref('')
	const goodList = ref([])
	const total = ref(0)
	
	const handleClose = (e) => {
		console.log(e)
	}
	onMounted(()=>{
		getGoodList().then((res)=>{
			goodList.value = res.rows
			total.value = res.total
		})
	})
</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
.searchBox{
	width: 100%;margin-bottom: 0.5rem;font-size: 1.4rem;letter-spacing: 0.04rem;
	padding-top: 36px;
	.seachOptions{
		display: flex;align-items: center;justify-content: space-between;
		margin-bottom: 1.5rem;
		.searchItem{
			display: inline-grid;grid-auto-flow: column;
			grid-gap: 2rem;
			.option{
				display: inline-flex;align-items: center;cursor: pointer;
				span{
					color: rgba(18,18,18,0.75);
					&:hover{
						color: rgb(18,18,18);
						text-decoration: underline;text-underline-offset: 0.3rem;
					}
				}
				.el-icon{
					margin-left: 5px;
				}
			}
		}
	}
	.seachParams{
		width: 100%;margin-top: 1.5rem;
		ul{
			display: flex;align-items: center;justify-content: flex-start;
			li{
				a{
					color: rgba(18,18,18,0.75);
					&:hover{
						color: rgb(18,18,18);
						text-decoration: underline;text-underline-offset: 0.3rem;
					}
				}
			}
			li + li{margin-left: 1.2rem;}
		}
	}
}
.listItem{
	margin-top: 2rem;
}
</style>
